.blogRoot {
    background-color: var(--background);
    font-size: 1.5em;
}

.blogRootFull {
    background-color: var(--background);
    padding: 10px;
}
.blogText {
    display: inline;
}

.blogHeading1 {
    font-size: 35px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading2 {
    font-size: 30px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading3 {
    font-size: 25px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading4 {
    font-size: 20px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading5 {
    font-size: 15px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading6 {
    font-size: 10px;
    padding-bottom: var(--shortSpacing)
}

.blogStrong {
    font-weight: bold;
}

.blogBreak {
    /* no-op */
}

.listItem {
    font-size: 20px;
}

.blogParagraph {
    padding-bottom: var(--shortSpacing)
}


.blogThematicBreak {

}

.blogBlockQuote {

}


.blogDelete {

}
.blogLink {
    text-decoration: underline;
    color: var(--accent);
}

.blogImage {
    width: 100%;
}

.table {
    width: 100%;
    margin:0px;
    padding:0px;
    border: none;
    border-collapse: collapse;
}

.tableCell {
    flex: 1;
    padding: 0; 
    margin: 0;
    display: inline-block;
}

.tableHead {
    background-color: var(--primary);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
}
.tableBody {
    background-color: var(--primary);
}

.tableRow {
    display: flex;
    flex-direction: row;
}

.unorderedList {
    list-style-type: square;
}

.inlineCode {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    display: inline-block;
}

@media only screen and (max-width : 870px) {
    .blogRoot {
        font-size: 1em;
    }
    .listItem {
        font-size: 1em;
    }
  }
.contactStatement {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 3em;
    display: flex;
    margin-bottom: var(--largeSpacing);
}

.socialStatement {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 2em;
    display: flex;
    margin-top: var(--largeSpacing);
    margin-bottom: var(--spacing);
}

.socialMediaList {
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialMediaItem {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-right: var(--shortSpacing);
    margin-top: var(--spacing);
    overflow: hidden;
}
.form {
    padding-right: 20%;
    padding-left: 20%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;

}
.sent {
    font-size: 3em;
    text-align: center;
    color: var(--accent)
}
.formItem {
    margin-bottom: var(--shortSpacing);
    box-sizing: border-box;
}

.submit {
    display: flex;
   align-items: center;
   justify-content: center;
}

.submit {
    padding: 10px;
}

@media only screen and (max-width : 870px) {
    .form {
        padding-right: 0px;
        padding-left: 0px;
    
    }
  }

.container {
    margin-bottom: var(--largeSpacing);
}
.techItem {
    display: flex;
    padding: var(--shortSpacing);
    justify-content: space-between;
    transition: width 1s;
    margin-bottom: var(--shortSpacing);
    background: rgb(46,46,46);
    background: linear-gradient(90deg, var(--element) 0%, var(--accent) 100%);
}


.title {
    font-size: 2em;
    margin-bottom: var(--spacing);
}
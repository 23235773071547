.container {
    padding-top: var(--spacing);
    padding-bottom: var(--spacing);
    padding-left: 20%;
    padding-right: 20%;
}
.button {
    margin-right: 10px;
    display: inline-block;
}

.row {
    display: flex;
    flex-direction: row;
    
}

@media only screen and (max-width : 870px) {
    .container {
        padding: var(--spacing);
        padding-left: 5%;
        padding-right: 5%;
    }
  }
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Regular.ttf) format('truetype');
}
:root {
  --primary: #111;
  --element: #2e2e2e;
  --secondary: #F7F7F2;
  --accent: #F0803C;
  --spacing: 30px;
  --largeSpacing: 100px;
  --shortSpacing: 15px;
}


html,body{
  background-color: var(--primary);
  color:white;
}
body {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.container {
    width: 100%;
    margin-bottom: var(--largeSpacing);
}
.title {
    font-size: 2em;
    margin-bottom: var(--spacing);
}
.element {
  transition: padding-left 1s, padding-right 1s;
  margin-bottom: var(--shortSpacing);
} 


.info {
    padding: var(--shortSpacing)
}

.companyTitle {
    margin-bottom: 2px;
}
.role {
    opacity: 0.8em;
    margin-bottom: 2px;
}
.tech {
    font-size: 0.7em;
    opacity: 0.8;
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date {
    font-size: 1.1em;
}
.dates {
    padding: var(--shortSpacing)
}


@media only screen and (max-width : 870px) {
    .king {
        padding-right: 0px;
      } 
      .five {
          padding-left: 0px;
      }
      .freelance {
          padding-left: 0px;
      }
  }
.button {
    border:none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    margin-right: var(--spacing);
    padding-bottom: 5px; /* this is to avoid flickering when hover is activated */
}
.nibble {
    transition: opacity 0.5s;
    margin-top: 2px;
    width: 30px;
    height: 5px;
    background-color: var(--accent);
    border-radius: 20px;
}
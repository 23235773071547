.container {
  
}
.input {
    text-decoration: none;
    border:none;
    outline:none;
    background-color: transparent;
    color: white;
    width: 100%;
    font-family: 'Lato', sans-serif;
    resize:vertical;
    box-sizing: border-box;
    padding: 10px;
}

.input:focus {
    text-decoration: none;
    outline:none;
    border:none;
    background-color: transparent;
}
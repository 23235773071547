.title{
    text-align: center;
}

.blog {
    height: 100px;
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: right;
}
.content {
    padding: var(--shortSpacing);
}
.date {
    font-size: 0.8em;
}
.blogTitle {
    font-size: 1.1em;
    margin-bottom: var(--shortSpacing);
}

@media only screen and (max-width : 870px) {
    .blog {
      
        font-size: 1.1em;
 
    }
  }
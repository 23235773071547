@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(/static/media/Lato-Regular.2d36b1a9.ttf) format('truetype');
}
:root {
  --primary: #111;
  --element: #2e2e2e;
  --secondary: #F7F7F2;
  --accent: #F0803C;
  --spacing: 30px;
  --largeSpacing: 100px;
  --shortSpacing: 15px;
}


html,body{
  background-color: #111;
  background-color: var(--primary);
  color:white;
}
body {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  text-align: center;
}
.HeaderScreen_container__1wTS7 {
    padding-top: var(--spacing);
    padding-bottom: var(--spacing);
    padding-left: 20%;
    padding-right: 20%;
}
.HeaderScreen_button__1AgFX {
    margin-right: 10px;
    display: inline-block;
}

.HeaderScreen_row__2TAD8 {
    display: flex;
    flex-direction: row;
    
}

@media only screen and (max-width : 870px) {
    .HeaderScreen_container__1wTS7 {
        padding: var(--spacing);
        padding-left: 5%;
        padding-right: 5%;
    }
  }
.HeaderButton_button__1DYbF {
    border:none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    margin-right: var(--spacing);
    padding-bottom: 5px; /* this is to avoid flickering when hover is activated */
}
.HeaderButton_nibble__1sOpA {
    transition: opacity 0.5s;
    margin-top: 2px;
    width: 30px;
    height: 5px;
    background-color: var(--accent);
    border-radius: 20px;
}
.blogRoot {
    background-color: var(--background);
    font-size: 1.5em;
}

.blogRootFull {
    background-color: var(--background);
    padding: 10px;
}
.blogText {
    display: inline;
}

.blogHeading1 {
    font-size: 35px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading2 {
    font-size: 30px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading3 {
    font-size: 25px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading4 {
    font-size: 20px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading5 {
    font-size: 15px;
    padding-bottom: var(--shortSpacing)
}
.blogHeading6 {
    font-size: 10px;
    padding-bottom: var(--shortSpacing)
}

.blogStrong {
    font-weight: bold;
}

.blogBreak {
    /* no-op */
}

.listItem {
    font-size: 20px;
}

.blogParagraph {
    padding-bottom: var(--shortSpacing)
}


.blogThematicBreak {

}

.blogBlockQuote {

}


.blogDelete {

}
.blogLink {
    text-decoration: underline;
    color: var(--accent);
}

.blogImage {
    width: 100%;
}

.table {
    width: 100%;
    margin:0px;
    padding:0px;
    border: none;
    border-collapse: collapse;
}

.tableCell {
    flex: 1 1;
    padding: 0; 
    margin: 0;
    display: inline-block;
}

.tableHead {
    background-color: var(--primary);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
}
.tableBody {
    background-color: var(--primary);
}

.tableRow {
    display: flex;
    flex-direction: row;
}

.unorderedList {
    list-style-type: square;
}

.inlineCode {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    display: inline-block;
}

@media only screen and (max-width : 870px) {
    .blogRoot {
        font-size: 1em;
    }
    .listItem {
        font-size: 1em;
    }
  }
.Loader_loader__2Ueas,
.Loader_loader__2Ueas:before,
.Loader_loader__2Ueas:after {
  background: var(--accent);
  -webkit-animation: Loader_load1__4BVhq 1s infinite ease-in-out;
  animation: Loader_load1__4BVhq 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.Loader_loader__2Ueas {
  color: var(--accent);
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Loader_loader__2Ueas:before,
.Loader_loader__2Ueas:after {
  position: absolute;
  top: 0;
  content: '';
}
.Loader_loader__2Ueas:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Loader_loader__2Ueas:after {
  left: 1.5em;
}
@-webkit-keyframes Loader_load1__4BVhq {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes Loader_load1__4BVhq {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.blogs_title__1mIDN{
    text-align: center;
}

.blogs_blog__R9qsw {
    height: 100px;
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: right;
}
.blogs_content__1Gfns {
    padding: var(--shortSpacing);
}
.blogs_date__2GEdv {
    font-size: 0.8em;
}
.blogs_blogTitle__2eEMi {
    font-size: 1.1em;
    margin-bottom: var(--shortSpacing);
}

@media only screen and (max-width : 870px) {
    .blogs_blog__R9qsw {
      
        font-size: 1.1em;
 
    }
  }
.Panel_panel__3fdzD {
 background-color: var(--element);
 width: 100%;
 border-radius: 20px;
 
}
.styles_profile__2Ya4w {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  margin-bottom: var(--largeSpacing);
}
.styles_info__3M8-T {
  margin-left: var(--spacing);
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
  /* Styles */
  }
  
  /* Smartphones (portrait) ----------- */
@media only screen and (max-width : 870px) {
  .styles_profile__2Ya4w {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .styles_info__3M8-T {
    margin-left: 0px;
    text-align: center;
  }
  .styles_container__3leOp{
    padding-left: 0px;
    padding-right: 0px;
 }
}
.Avatar_avatar__2cLdR {
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border: double 2px transparent;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #2e2e2e,#F0803C);
    background-origin: border-box;
    background-clip: content-box, border-box;
}
.HistoryReel_container__1MWhh {
    width: 100%;
    margin-bottom: var(--largeSpacing);
}
.HistoryReel_title__2FTOp {
    font-size: 2em;
    margin-bottom: var(--spacing);
}
.HistoryReel_element__30J2f {
  transition: padding-left 1s, padding-right 1s;
  margin-bottom: var(--shortSpacing);
} 


.HistoryReel_info__3T6ob {
    padding: var(--shortSpacing)
}

.HistoryReel_companyTitle__2BZNy {
    margin-bottom: 2px;
}
.HistoryReel_role__3sLll {
    opacity: 0.8em;
    margin-bottom: 2px;
}
.HistoryReel_tech__1oIPN {
    font-size: 0.7em;
    opacity: 0.8;
}
.HistoryReel_row__2-2ui {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.HistoryReel_date__393c- {
    font-size: 1.1em;
}
.HistoryReel_dates__2GIaL {
    padding: var(--shortSpacing)
}


@media only screen and (max-width : 870px) {
    .HistoryReel_king__1PJUS {
        padding-right: 0px;
      } 
      .HistoryReel_five__3RM9y {
          padding-left: 0px;
      }
      .HistoryReel_freelance__1fHUf {
          padding-left: 0px;
      }
  }

.Technology_container__2I8yr {
    margin-bottom: var(--largeSpacing);
}
.Technology_techItem__3Cq6O {
    display: flex;
    padding: var(--shortSpacing);
    justify-content: space-between;
    transition: width 1s;
    margin-bottom: var(--shortSpacing);
    background: rgb(46,46,46);
    background: linear-gradient(90deg, var(--element) 0%, var(--accent) 100%);
}


.Technology_title__1Ji3h {
    font-size: 2em;
    margin-bottom: var(--spacing);
}
.Button_button__goX_m {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--element);
    width: 200px;
    height: 40px;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Button_button__goX_m:active { 
    border: 1px solid var(--accent);
}
.Input_container__1SMcS {
  
}
.Input_input__1iBRm {
    text-decoration: none;
    border:none;
    outline:none;
    background-color: transparent;
    color: white;
    width: 100%;
    font-family: 'Lato', sans-serif;
    resize:vertical;
    box-sizing: border-box;
    padding: 10px;
}

.Input_input__1iBRm:focus {
    text-decoration: none;
    outline:none;
    border:none;
    background-color: transparent;
}
.Contact_contactStatement__hn0u- {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 3em;
    display: flex;
    margin-bottom: var(--largeSpacing);
}

.Contact_socialStatement__1WEYH {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 2em;
    display: flex;
    margin-top: var(--largeSpacing);
    margin-bottom: var(--spacing);
}

.Contact_socialMediaList__3oN_U {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Contact_socialMediaItem__2umGt {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-right: var(--shortSpacing);
    margin-top: var(--spacing);
    overflow: hidden;
}
.Contact_form__3-gOw {
    padding-right: 20%;
    padding-left: 20%;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;

}
.Contact_sent__igmo2 {
    font-size: 3em;
    text-align: center;
    color: var(--accent)
}
.Contact_formItem__1SPzb {
    margin-bottom: var(--shortSpacing);
    box-sizing: border-box;
}

.Contact_submit__3MIZO {
    display: flex;
   align-items: center;
   justify-content: center;
}

.Contact_submit__3MIZO {
    padding: 10px;
}

@media only screen and (max-width : 870px) {
    .Contact_form__3-gOw {
        padding-right: 0px;
        padding-left: 0px;
    
    }
  }

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: var(--element);
    width: 200px;
    height: 40px;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button:active { 
    border: 1px solid var(--accent);
}
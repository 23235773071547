.profile {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  margin-bottom: var(--largeSpacing);
}
.info {
  margin-left: var(--spacing);
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width : 321px) {
  /* Styles */
  }
  
  /* Smartphones (portrait) ----------- */
@media only screen and (max-width : 870px) {
  .profile {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .info {
    margin-left: 0px;
    text-align: center;
  }
  .container{
    padding-left: 0px;
    padding-right: 0px;
 }
}